import request from '@/utils/request'

export function getQueryList(params) {
    return request({
        url: '/api/Query/List',
        data: {...params },
        method: 'post',
        header: {
            'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
        },

    })
}