<template>
  <div class="container">
    <van-sticky>
      <van-nav-bar title="搜索结果" left-arrow @click-left="$router.back()" />
    </van-sticky>
    <van-search
      v-model="page.filterData.keyWords"
      @search="onSearch"
      placeholder="请输入搜索关键词"
    />

    <van-list
      v-model="upLoading"
      :finished="finished"
      @load="onLoad"
      finished-text="没有更多了"
    >
      <van-cell-group>
        <!-- art_id有可能是大数字 超过一定长度会转成 BigNumber  要toString一下 -->
        <van-cell
          @click="toDetail(item)"
          v-for="item in articles"
          :key="item.id.toString()"
        >
          <van-col span="24" align="bottom">
            <div
              style="display: table-cell; vertical-align: middle"
              class="zntitle van-multi-ellipsis--l3"
            >
              <h3 style="display: inline-block">{{ item.title }}</h3>
            </div>
            <div>
              <div style="float: left">
                <van-tag color="#9d1d22">
                  {{ getTypeText(item.typeId) }}</van-tag
                >
              </div>
              <div style="float: right">{{ fixTime(item.creationTime) }}</div>
            </div>
          </van-col>
        </van-cell>
      </van-cell-group>
    </van-list>
  </div>
</template>

<script>
import { getQueryList } from "@/api/query";

//const key = "zn-history";
export default {
  name: "queryList",
  data() {
    return {
      keyword: "",
      upLoading: false,
      finished: false,
      articles: [],
      page: {
        current: 1,
        pageSize: 10,
        filterData: {
          keyWords: "",
          type:0,
        },
      },
    };
  },
  components: {},
  created() {
    this.initData();
  },
  mounted() {},

  methods: {
    fixTime(creationTime){
      if(!creationTime){
        return "";
      }
      if(creationTime.indexOf('1900')>=0){
        return "";
      }
      return creationTime;
    },
    initData() {
      let { q,type } = this.$route.query;
      this.page.filterData.keyWords = q;
      if(type){
        this.page.filterData.type = type;
      }
      
      //let type = this.$route.query.type;
    },
    onSearch() {
      this.articles = [];
      this.page.current = 1;
      this.page.pageSize = 10;
      this.onLoad();
    },
    toDetail(item) {
      switch (true) {
        //90 讲师 311 线下分类大类 312线上分类大类 313 线下分类大类 314 线上分类大类 321线下课程 322线上课程
        case (item.typeId == 10): {
          this.$router.push({
            path: "/userHomepage",
            query: {para: item.id },
          });
          return;
        }
        case (item.typeId == 90): {
          this.$router.push({
            path: "/course/list",
            query: {type:'b', tid: item.id },
          });
          return;
        }
        case (item.typeId == 311): {
          this.$router.push({
            path: "/course/list",
            query: {type:'b', id: item.id },
          });
          return;
        }
        case (item.typeId == 313): {
          this.$router.push({
            path: "/course/list",
            query: {type:'b', id2: item.id },
          });
          return;
        }
         case (item.typeId == 312): {
          this.$router.push({
            path: "/course/list",
            query: {type:'a', id: item.id },
          });
          return;
        }
        case (item.typeId == 314): {
          this.$router.push({
            path: "/course/list",
            query: { type:'a', id: item.id },
          });
          return;
        }
        case (item.typeId == 321): {
          this.$router.push({
            path: "/course/courseofflinedetail",
            query: { para: item.id },
          });
          return;
        }
        case (item.typeId == 322): {
          this.$router.push({
            path: "/course/detail",
            query: { para: item.id },
          });
          return;
        }
        case item.typeId >= 100 && item.typeId < 200: {
          this.$router.push({
            path: "/article/detail",
            query: { para: item.id, type: item.typeId },
          });
          return;
        }

        case (item.typeId == 200): {
          this.$router.push({
            path: "/product/detail",
            query: { para: item.id },
          });
          return;
        }
        case (item.typeId == 300): {
          this.$router.push({
            path: "/course/detail",
            query: { para: item.id },
          });
          return;
        }
        case (item.typeId == 400): {
          this.$router.push({ path: "/bbs/detail", query: { para: item.id },back: true, });
          return;
        }
        case (item.typeId == 500): {
          this.$router.push({
            path: "/clouddisk",
            query: { folderID: item.id },
          });
          return;
        }
        case (item.typeId == 501): {
          this.$router.push({
            path: "/clouddisk/detail",
            query: { para: item.id },
          });
          return;
        }

        default: {
          return;
        }
      }
    },

    getTypeText(id) {
      switch (id) {
        case 10:{
          return "中酿员工"
        }
        case 90: {
          return "中酿讲师";
        }
        case 311:
        case 312:
        case 313:
        case 314: {
          return "课程分类";
        }
        case 321:
        {
          return "线下课程";
        }
        case 322:
        {
          return "线上课程";
        }
        case 100: {
          return "中酿资讯";
        }
        case 101: {
          return "中酿公告";
        }
        case 102: {
          return "技能加持";
        }
        case 103: {
          return "荣誉奋斗者";
        }
        case 104: {
          return "KM系统说明";
        }
        case 105: {
          return "KM系统说明";
        }
        case 106: {
          return "市场宣传";
        }
        case 107: {
          return "系统说明";
        }
        case 108: {
          return "中酿内刊";
        }
        case 110: {
          return "中酿宣传";
        }
        case 111: {
          return "制度须知";
        }
        case 112: {
          return "审计公示";
        }
        case 200: {
          return "中酿产品";
        }
        case 300: {
          return "中酿学院";
        }
        case 400: {
          return "中酿心声";
        }
        case 500:
        case 501: {
          return "资料库";
        }

        default: {
          return "";
        }
      }
    },

    async onLoad() {
      let aresult = await getQueryList({ ...this.page }); //({ ...this.page })
      this.articles.push(...aresult.data.data);
      this.upLoading = false;
      if (this.page.current * this.page.pageSize < aresult.data.total) {
        this.page.current++;
      } else {
        this.finished = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.w100 {
  /deep/ img {
    width: 100%;
    max-height: 200px;
  }
}
.zntitle {
  height: 80px;
}
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.detail {
  padding: 46px 10px 44px;
  .title {
    font-size: 18px;
    line-height: 2;
  }
  .zan {
    text-align: center;
    padding: 10px 0;
    .active {
      border-color: red;
      color: red;
    }
  }
  .author {
    padding: 10px 0;
    display: flex;
    position: sticky;
    background-color: #fff;
    top: 46px;
    z-index: 2;
    .text {
      flex: 1;
      padding-left: 10px;
      line-height: 1.5;
      .name {
        font-size: 14px;
        margin: 0;
      }
      .time {
        margin: 0;
        font-size: 12px;
        color: #999;
      }
    }
  }
  .content {
    padding: 20px 0;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;
    /deep/ img {
      max-width: 100%;
      background: #f9f9f9;
    }
    /deep/ code {
      white-space: pre-wrap;
    }
  }
}
</style>
